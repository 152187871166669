/// <reference types="./router.d.mts" />
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $uri from "../../gleam_stdlib/gleam/uri.mjs";
import * as $modem from "../../modem/modem.mjs";
import * as $document from "../frontend/effects/document.mjs";
import { toList, CustomType as $CustomType, makeError } from "../gleam.mjs";

export class Home extends $CustomType {}

export class Search extends $CustomType {
  constructor(query) {
    super();
    this.query = query;
  }
}

export class Packages extends $CustomType {}

export class Trending extends $CustomType {}

export class Analytics extends $CustomType {}

function handle_search_path(uri) {
  let _pipe = uri.query;
  let _pipe$1 = $option.map(_pipe, $uri.parse_query);
  let _pipe$2 = $option.then$(
    _pipe$1,
    (query_params) => {
      let _pipe$2 = query_params;
      let _pipe$3 = $result.unwrap(_pipe$2, toList([]));
      let _pipe$4 = $list.key_find(_pipe$3, "q");
      return $option.from_result(_pipe$4);
    },
  );
  let _pipe$3 = $option.map(_pipe$2, (var0) => { return new Search(var0); });
  return $option.unwrap(_pipe$3, new Home());
}

export function parse_uri(uri) {
  let $ = $uri.path_segments(uri.path);
  if ($.hasLength(1) && $.head === "search") {
    return handle_search_path(uri);
  } else if ($.hasLength(1) && $.head === "packages") {
    return new Packages();
  } else if ($.hasLength(1) && $.head === "trending") {
    return new Trending();
  } else if ($.hasLength(1) && $.head === "analytics") {
    return new Analytics();
  } else {
    return new Home();
  }
}

export function update_page_title(route) {
  if (route instanceof Home) {
    return $document.update_title("Gloogle");
  } else if (route instanceof Packages) {
    return $document.update_title("Gloogle — Packages");
  } else if (route instanceof Search) {
    let q = route.query;
    return $document.update_title("Gloogle — Search " + q);
  } else if (route instanceof Trending) {
    return $document.update_title("Gloogle — Trending");
  } else {
    return $document.update_title("Gloogle — Analytics");
  }
}

export function to_uri(route) {
  let $ = (() => {
    if (route instanceof Home) {
      return $uri.parse("/");
    } else if (route instanceof Search) {
      let query = route.query;
      return $uri.parse("/search?" + query);
    } else if (route instanceof Packages) {
      return $uri.parse("/packages");
    } else if (route instanceof Trending) {
      return $uri.parse("/trending");
    } else {
      return $uri.parse("/analytics");
    }
  })();
  if (!$.isOk()) {
    throw makeError(
      "let_assert",
      "frontend/router",
      50,
      "to_uri",
      "Pattern match failed, no pattern matched the value.",
      { value: $ }
    )
  }
  let uri = $[0];
  return uri;
}

export function push(route) {
  let uri = to_uri(route);
  return $modem.push(uri.path, uri.query, uri.fragment);
}

export function replace(route) {
  let uri = to_uri(route);
  return $modem.replace(uri.path, uri.query, uri.fragment);
}
