/// <reference types="./metadata.d.mts" />
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../gleam_stdlib/gleam/pair.mjs";
import * as $implementations from "../data/implementations.mjs";
import { Implementations } from "../data/implementations.mjs";
import { toList, prepend as listPrepend, CustomType as $CustomType } from "../gleam.mjs";
import * as $decoder_extra from "../gleam/decoder_extra.mjs";

export class Metadata extends $CustomType {
  constructor(deprecation, implementations) {
    super();
    this.deprecation = deprecation;
    this.implementations = implementations;
  }
}

export function decode(dyn) {
  return $dynamic.decode2(
    (var0, var1) => { return new Metadata(var0, var1); },
    $decoder_extra.completely_option("deprecation"),
    $dynamic.optional_field(
      "implementations",
      $dynamic.decode3(
        (var0, var1, var2) => { return new Implementations(var0, var1, var2); },
        $dynamic.field("gleam", $dynamic.bool),
        $dynamic.field("uses_erlang_externals", $dynamic.bool),
        $dynamic.field("uses_javascript_externals", $dynamic.bool),
      ),
    ),
  )(dyn);
}

function encode_implementations(i) {
  let _pipe = $json.object(
    toList([
      ["gleam", $json.bool(i.gleam)],
      ["uses_erlang_externals", $json.bool(i.uses_erlang_externals)],
      ["uses_javascript_externals", $json.bool(i.uses_javascript_externals)],
    ]),
  );
  return ((_capture) => { return $pair.new$("implementations", _capture); })(
    _pipe,
  );
}

function append_optional(elems, data, mapper) {
  if (data instanceof $option.None) {
    return elems;
  } else {
    let data$1 = data[0];
    return listPrepend(mapper(data$1), elems);
  }
}

export function encode(metadata) {
  let deprecation = metadata.deprecation;
  let implementations = metadata.implementations;
  let _pipe = toList([]);
  let _pipe$1 = append_optional(
    _pipe,
    deprecation,
    (d) => { return ["deprecation", $json.string(d)]; },
  );
  let _pipe$2 = append_optional(
    _pipe$1,
    implementations,
    encode_implementations,
  );
  return $json.object(_pipe$2);
}
