/// <reference types="./msg.d.mts" />
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $analytics from "../../interfaces/data/analytics.mjs";
import * as $package from "../../interfaces/data/package.mjs";
import * as $search_result from "../data/search_result.mjs";
import * as $discuss from "../frontend/discuss.mjs";
import * as $router from "../frontend/router.mjs";
import { CustomType as $CustomType } from "../gleam.mjs";

export class Functions extends $CustomType {}

export class Types extends $CustomType {}

export class Aliases extends $CustomType {}

export class Documented extends $CustomType {}

export class ShowOldPackages extends $CustomType {}

export class DocumentationSearch extends $CustomType {}

export class VectorSearch extends $CustomType {}

export class ApiReturnedAnalytics extends $CustomType {
  constructor(analytics) {
    super();
    this.analytics = analytics;
  }
}

export class ApiReturnedPackages extends $CustomType {
  constructor(packages) {
    super();
    this.packages = packages;
  }
}

export class ApiReturnedSearchResults extends $CustomType {
  constructor(input, search_results) {
    super();
    this.input = input;
    this.search_results = search_results;
  }
}

export class ApiReturnedTrendings extends $CustomType {
  constructor(trendings) {
    super();
    this.trendings = trendings;
  }
}

export class AppRequiredDiscussToast extends $CustomType {
  constructor(message) {
    super();
    this.message = message;
  }
}

export class BrowserChangedRoute extends $CustomType {
  constructor(route) {
    super();
    this.route = route;
  }
}

export class BrowserResizedViewport extends $CustomType {
  constructor(is_mobile) {
    super();
    this.is_mobile = is_mobile;
  }
}

export class UserClickedSidebarName extends $CustomType {
  constructor(id) {
    super();
    this.id = id;
  }
}

export class UserFocusedSearch extends $CustomType {
  constructor(event) {
    super();
    this.event = event;
  }
}

export class UserInputtedSearch extends $CustomType {
  constructor(query) {
    super();
    this.query = query;
  }
}

export class UserPressedEscape extends $CustomType {}

export class UserSubmittedSearch extends $CustomType {}

export class UserToggledFilter extends $CustomType {
  constructor(filter, value) {
    super();
    this.filter = filter;
    this.value = value;
  }
}
