/// <reference types="./analytics.d.mts" />
import * as $birl from "../../birl/birl.mjs";
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import { toList, CustomType as $CustomType } from "../gleam.mjs";

export class Analytics extends $CustomType {
  constructor(total_searches, total_signatures, total_indexed, timeseries, ranked, popular) {
    super();
    this.total_searches = total_searches;
    this.total_signatures = total_signatures;
    this.total_indexed = total_indexed;
    this.timeseries = timeseries;
    this.ranked = ranked;
    this.popular = popular;
  }
}

export class Package extends $CustomType {
  constructor(name, repository, rank, popularity) {
    super();
    this.name = name;
    this.repository = repository;
    this.rank = rank;
    this.popularity = popularity;
  }
}

export function encode_package(package$) {
  let name = package$.name;
  let repository = package$.repository;
  let rank = package$.rank;
  let popularity = package$.popularity;
  return $json.object(
    toList([
      ["name", $json.string(name)],
      ["repository", $json.string(repository)],
      ["rank", $json.int(rank)],
      ["popularity", $json.nullable(popularity, $json.int)],
    ]),
  );
}

export function encode(analytics) {
  return $json.object(
    toList([
      ["total_searches", $json.int(analytics.total_searches)],
      ["total_signatures", $json.int(analytics.total_signatures)],
      ["total_indexed", $json.int(analytics.total_indexed)],
      ["ranked", $json.array(analytics.ranked, encode_package)],
      ["popular", $json.array(analytics.popular, encode_package)],
      [
        "timeseries",
        $json.array(
          analytics.timeseries,
          (row) => {
            return $json.object(
              toList([
                ["count", $json.int(row[0])],
                ["date", $json.string($birl.to_iso8601(row[1]))],
              ]),
            );
          },
        ),
      ],
    ]),
  );
}

export function decode_package(dyn) {
  return $dynamic.decode4(
    (var0, var1, var2, var3) => { return new Package(var0, var1, var2, var3); },
    $dynamic.field("name", $dynamic.string),
    $dynamic.field("repository", $dynamic.string),
    $dynamic.field("rank", $dynamic.int),
    $dynamic.field("popularity", $dynamic.optional($dynamic.int)),
  )(dyn);
}

export function decode(dyn) {
  return $dynamic.decode6(
    (var0, var1, var2, var3, var4, var5) => {
      return new Analytics(var0, var1, var2, var3, var4, var5);
    },
    $dynamic.field("total_searches", $dynamic.int),
    $dynamic.field("total_signatures", $dynamic.int),
    $dynamic.field("total_indexed", $dynamic.int),
    $dynamic.field(
      "timeseries",
      $dynamic.list(
        $dynamic.decode2(
          $pair.new$,
          $dynamic.field("count", $dynamic.int),
          $dynamic.field(
            "date",
            (dyn) => {
              let _pipe = $dynamic.string(dyn);
              return $result.then$(
                _pipe,
                (t) => {
                  let _pipe$1 = $birl.parse(t);
                  return $result.replace_error(_pipe$1, toList([]));
                },
              );
            },
          ),
        ),
      ),
    ),
    $dynamic.field("ranked", $dynamic.list(decode_package)),
    $dynamic.field("popular", $dynamic.list(decode_package)),
  )(dyn);
}
