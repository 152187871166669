/// <reference types="./bright.d.mts" />
import * as $bool from "../gleam_stdlib/gleam/bool.mjs";
import * as $dynamic from "../gleam_stdlib/gleam/dynamic.mjs";
import * as $function from "../gleam_stdlib/gleam/function.mjs";
import * as $list from "../gleam_stdlib/gleam/list.mjs";
import * as $pair from "../gleam_stdlib/gleam/pair.mjs";
import * as $effect from "../lustre/lustre/effect.mjs";
import { coerce, areReferentiallyEqual as are_referentially_equal } from "./bright.ffi.mjs";
import { toList, prepend as listPrepend, CustomType as $CustomType, makeError } from "./gleam.mjs";

class Bright extends $CustomType {
  constructor(data, computed, selections, past_selections, effects) {
    super();
    this.data = data;
    this.computed = computed;
    this.selections = selections;
    this.past_selections = past_selections;
    this.effects = effects;
  }
}

export function init(data, computed) {
  return new Bright(data, computed, toList([]), toList([]), toList([]));
}

export function update(bright, update_, next) {
  let $ = update_(bright.data);
  let data = $[0];
  let effects = $[1];
  let effects$1 = listPrepend($dynamic.from(effects), bright.effects);
  let _pipe = bright.withFields({ data: data, effects: effects$1 });
  return next(_pipe);
}

export function compute(bright, compute_) {
  let _pipe = compute_(bright.data, bright.computed);
  return ((computed) => { return bright.withFields({ computed: computed }); })(
    _pipe,
  );
}

export function run(bright, guard_) {
  let _pipe = guard_(bright.data, bright.computed);
  let _pipe$1 = $dynamic.from(_pipe);
  let _pipe$2 = ((_capture) => {
    return $list.prepend(bright.effects, _capture);
  })(_pipe$1);
  return ((effects) => { return bright.withFields({ effects: effects }); })(
    _pipe$2,
  );
}

export function view(bright, viewer) {
  return viewer(bright.data, bright.computed);
}

export function step(bright, next) {
  let bright$1 = bright[0];
  let effs = bright[1];
  let $ = next(bright$1);
  let model = $[0];
  let effs_ = $[1];
  return [model, $effect.batch(toList([effs, effs_]))];
}

export function return$(a) {
  return [a, $effect.none()];
}

function lazy_wrap(bright, selector, setter, compute_) {
  let selected_data = selector(bright.data);
  let selections = listPrepend($dynamic.from(selected_data), bright.selections);
  let bright$1 = bright.withFields({ selections: selections });
  let $ = bright$1.past_selections;
  if ($.hasLength(0)) {
    return setter(bright$1, compute_);
  } else {
    let value = $.head;
    let past_selections = $.tail;
    let _pipe = bright$1.withFields({ past_selections: past_selections });
    return (() => {
      let $1 = are_referentially_equal(value, selected_data);
      if ($1) {
        return $function.identity;
      } else {
        return (_capture) => { return setter(_capture, compute_); };
      }
    })()(_pipe);
  }
}

export function lazy_compute(bright, selector, compute_) {
  return lazy_wrap(bright, selector, compute, compute_);
}

export function lazy_run(bright, selector, guard_) {
  return lazy_wrap(bright, selector, run, guard_);
}

function panic_if_different_computations_count(old_computations, computations) {
  let count = $list.length(old_computations);
  return $bool.guard(
    count === 0,
    undefined,
    () => {
      let is_same_count = count === $list.length(computations);
      return $bool.guard(
        is_same_count,
        undefined,
        () => {
          throw makeError(
            "panic",
            "bright",
            253,
            "",
            "Memoized computed should be consistent over time, otherwise memo can not work.",
            {}
          )
        },
      );
    },
  );
}

export function start(bright, next) {
  let old_computations = bright.past_selections;
  let new_data = next(bright);
  let all_effects = (() => {
    let _pipe = $dynamic.from(new_data.effects);
    let _pipe$1 = coerce(_pipe);
    return $list.reverse(_pipe$1);
  })();
  panic_if_different_computations_count(old_computations, new_data.selections);
  let past_selections = $list.reverse(new_data.selections);
  let _pipe = new_data.withFields({
    past_selections: past_selections,
    selections: toList([]),
    effects: toList([])
  });
  return $pair.new$(_pipe, $effect.batch(all_effects));
}
