/// <reference types="./view.d.mts" />
import * as $bright from "../../bright/bright.mjs";
import * as $el from "../../lustre/lustre/element.mjs";
import * as $s from "../../sketch/sketch.mjs";
import * as $media from "../../sketch/sketch/media.mjs";
import * as $size from "../../sketch/sketch/size.mjs";
import { px } from "../../sketch/sketch/size.mjs";
import * as $magic from "../../sketch_magic/sketch/magic.mjs";
import * as $h from "../../sketch_magic/sketch/magic/element/html.mjs";
import * as $model from "../data/model.mjs";
import * as $palette from "../frontend/colors/palette.mjs";
import * as $router from "../frontend/router.mjs";
import * as $body from "../frontend/view/body/body.mjs";
import * as $footer from "../frontend/view/footer/footer.mjs";
import * as $navbar from "../frontend/view/navbar/navbar.mjs";
import { toList } from "../gleam.mjs";

function layout(attributes, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("grid"),
      $s.grid_template_areas(
        toList(["sidebar navbar", "sidebar main", "sidebar footer"]),
      ),
      $s.property("--a-color", $palette.dark.faff_pink),
      $s.grid_template_columns("auto 1fr"),
      $s.grid_template_rows("auto 1fr auto"),
      $s.min_height($size.vh(100)),
      $s.media(
        $media.max_width(px(700)),
        toList([
          $s.grid_template_areas(toList(["navbar", "main", "footer"])),
          $s.grid_template_columns("1fr"),
        ]),
      ),
    ]),
  );
  return $h.div(_pipe, attributes, children);
}

export function view(model) {
  return $magic.render(
    toList([$magic.node()]),
    () => {
      return $bright.view(
        model,
        (data, _) => {
          return layout(
            toList([]),
            toList([
              $navbar.navbar(data),
              $body.body(data),
              (() => {
                let $ = data.route;
                if ($ instanceof $router.Home) {
                  return $footer.view();
                } else if ($ instanceof $router.Search) {
                  return $footer.search_bar(data);
                } else {
                  return $el.none();
                }
              })(),
            ]),
          );
        },
      );
    },
  );
}
