/// <reference types="./type_search.d.mts" />
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $kind from "../data/kind.mjs";
import * as $metadata from "../data/metadata.mjs";
import * as $signature from "../data/signature.mjs";
import { toList, CustomType as $CustomType } from "../gleam.mjs";
import * as $decode_ from "../gleam/decoder_extra.mjs";

export class TypeSearch extends $CustomType {
  constructor(type_name, documentation, signature_kind, metadata, json_signature, module_name, package_name, version) {
    super();
    this.type_name = type_name;
    this.documentation = documentation;
    this.signature_kind = signature_kind;
    this.metadata = metadata;
    this.json_signature = json_signature;
    this.module_name = module_name;
    this.package_name = package_name;
    this.version = version;
  }
}

export function decode(dyn) {
  return $dynamic.decode8(
    (var0, var1, var2, var3, var4, var5, var6, var7) => {
      return new TypeSearch(var0, var1, var2, var3, var4, var5, var6, var7);
    },
    $dynamic.field("type_name", $dynamic.string),
    $dynamic.field("documentation", $dynamic.string),
    $dynamic.field("signature_kind", $kind.decode),
    $dynamic.field("metadata", $decode_.json($metadata.decode)),
    $dynamic.field("json_signature", $decode_.json($signature.decode)),
    $dynamic.field("module_name", $dynamic.string),
    $dynamic.field("package_name", $dynamic.string),
    $dynamic.field("version", $dynamic.string),
  )(dyn);
}

export function encode(item) {
  return $json.object(
    toList([
      ["type_name", $json.string(item.type_name)],
      ["documentation", $json.string(item.documentation)],
      ["signature_kind", $kind.encode(item.signature_kind)],
      ["metadata", $metadata.encode(item.metadata)],
      ["json_signature", $signature.encode(item.json_signature)],
      ["module_name", $json.string(item.module_name)],
      ["package_name", $json.string(item.package_name)],
      ["version", $json.string(item.version)],
    ]),
  );
}
