/// <reference types="./update.d.mts" />
import * as $bool from "../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../gleam_stdlib/gleam/pair.mjs";
import * as $toast from "../../grille_pain/grille_pain/lustre/toast.mjs";
import * as $analytics from "../../interfaces/data/analytics.mjs";
import * as $package from "../../interfaces/data/package.mjs";
import * as $effect from "../../lustre/lustre/effect.mjs";
import * as $model from "../data/model.mjs";
import * as $msg from "../data/msg.mjs";
import * as $search_result from "../data/search_result.mjs";
import * as $discuss from "../frontend/discuss.mjs";
import * as $api from "../frontend/effects/api.mjs";
import * as $window from "../frontend/effects/window.mjs";
import * as $errors from "../frontend/errors.mjs";
import * as $router from "../frontend/router.mjs";
import { toList, isEqual } from "../gleam.mjs";
import * as $toast_error from "../toast/error.mjs";

export function handle_analytics(model, analytics) {
  let _pipe = model;
  let _pipe$1 = $model.update_analytics(_pipe, analytics);
  return $pair.new$(_pipe$1, $effect.none());
}

export function handle_packages(model, packages) {
  let _pipe = model.withFields({ packages: packages });
  return $pair.new$(_pipe, $effect.none());
}

export function handle_search_results(model, input, search_results) {
  let _pipe = search_results;
  let _pipe$1 = ((_capture) => {
    return $model.update_search_results(model, input, _capture);
  })(_pipe);
  let _pipe$2 = $model.toggle_loading(_pipe$1);
  return $pair.new$(_pipe$2, $router.push(new $router.Search("q=" + input)));
}

export function handle_trendings(model, trendings) {
  let _pipe = trendings;
  let _pipe$1 = ((_capture) => {
    return $model.update_trendings(model, _capture);
  })(_pipe);
  return $pair.new$(_pipe$1, $effect.none());
}

export function handle_discuss_toast(model, message) {
  let _pipe = message;
  let _pipe$1 = $toast_error.describe_http_error(_pipe);
  let _pipe$2 = $option.map(_pipe$1, $errors.capture_message);
  let _pipe$3 = $option.map(_pipe$2, $toast.error);
  let _pipe$4 = $option.unwrap(_pipe$3, $effect.none());
  return ((_capture) => { return $pair.new$(model, _capture); })(_pipe$4);
}

export function handle_changed_route(model, route) {
  let model$1 = $model.update_route(model, route);
  let _pipe = (() => {
    if (route instanceof $router.Home) {
      return $model.update_input(model$1, "");
    } else if (route instanceof $router.Packages) {
      return $model.update_input(model$1, "");
    } else if (route instanceof $router.Trending) {
      return $model.update_input(model$1, "");
    } else if (route instanceof $router.Analytics) {
      return $model.update_input(model$1, "");
    } else {
      let q = route.query;
      let _pipe = $model.update_input(model$1, q);
      return $model.update_submitted_input(_pipe);
    }
  })();
  return $pair.new$(_pipe, $router.update_page_title(route));
}

export function handle_resized_viewport(model, is_mobile) {
  let _pipe = model;
  let _pipe$1 = $model.update_is_mobile(_pipe, is_mobile);
  return $pair.new$(_pipe$1, $effect.none());
}

export function handle_clicked_sidebar_name(model, id) {
  let _pipe = $window.scroll_to(id);
  return ((_capture) => { return $pair.new$(model, _capture); })(_pipe);
}

export function handle_focused_search(model, event) {
  return [model, $window.focus("search-input", event)];
}

export function handle_inputted_search(model, content) {
  let _pipe = model;
  let _pipe$1 = $model.update_input(_pipe, content);
  return $pair.new$(_pipe$1, $effect.none());
}

export function handle_pressed_escape(model) {
  return [model, $window.blur()];
}

export function handle_submitted_search(model) {
  return $bool.guard(
    model.input === "",
    [model, $effect.none()],
    () => {
      return $bool.guard(
        model.loading,
        [model, $effect.none()],
        () => {
          let new_model = $model.update_submitted_input(model);
          let $ = $dict.get(new_model.search_results, new_model.submitted_input);
          if ($.isOk()) {
            let new_route = new $router.Search(new_model.submitted_input);
            let is_same_route = isEqual(new_model.route, new_route);
            return $bool.guard(
              is_same_route,
              [new_model, $effect.none()],
              () => {
                let _pipe = toList([
                  $router.push(
                    new $router.Search("q=" + new_model.submitted_input),
                  ),
                ]);
                let _pipe$1 = $list.prepend(_pipe, $window.blur());
                let _pipe$2 = $effect.batch(_pipe$1);
                return ((_capture) => { return $pair.new$(new_model, _capture); })(
                  _pipe$2,
                );
              },
            );
          } else {
            let effects = $effect.batch(
              toList([$api.get_search(model), $window.blur()]),
            );
            let _pipe = $model.toggle_loading(new_model);
            return $pair.new$(_pipe, effects);
          }
        },
      );
    },
  );
}

export function handle_toggle_filter(model, filter, value) {
  let _pipe = (() => {
    if (filter instanceof $msg.Functions) {
      let value$1 = value;
      return model.withFields({ keep_functions: value$1 });
    } else if (filter instanceof $msg.Types) {
      let value$1 = value;
      return model.withFields({ keep_types: value$1 });
    } else if (filter instanceof $msg.Aliases) {
      let value$1 = value;
      return model.withFields({ keep_aliases: value$1 });
    } else if (filter instanceof $msg.Documented) {
      let value$1 = value;
      return model.withFields({ keep_documented: value$1 });
    } else if (filter instanceof $msg.ShowOldPackages) {
      let value$1 = value;
      return model.withFields({ show_old_packages: value$1 });
    } else if (filter instanceof $msg.VectorSearch) {
      let value$1 = value;
      return model.withFields({ show_vector_search: value$1 });
    } else {
      let value$1 = value;
      return model.withFields({ show_documentation_search: value$1 });
    }
  })();
  let _pipe$1 = $model.update_search_results_filter(_pipe);
  return $pair.new$(_pipe$1, $effect.none());
}
