/// <reference types="./magic.d.mts" />
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $el from "../../lustre/lustre/element.mjs";
import * as $html from "../../lustre/lustre/element/html.mjs";
import * as $sketch from "../../sketch/sketch.mjs";
import { Ok, toList, CustomType as $CustomType, makeError } from "../gleam.mjs";
import * as $ffi from "../sketch/internals/ffi.mjs";

export class CssStyleSheet extends $CustomType {
  constructor(stylesheet) {
    super();
    this.stylesheet = stylesheet;
  }
}

export class NodeStyleSheet extends $CustomType {}

export function setup(cache) {
  $ffi.set_cache(cache);
  return new Ok(cache);
}

function render_css(content, stylesheet, view) {
  $ffi.set_stylesheet(content, stylesheet);
  return view;
}

function render_node(content, view) {
  let style = $html.style(toList([]), content);
  return $el.fragment(toList([style, view]));
}

function render_stylesheet(content, stylesheet, view) {
  if (stylesheet instanceof CssStyleSheet) {
    let stylesheet$1 = stylesheet.stylesheet;
    return render_css(content, stylesheet$1, view);
  } else {
    return render_node(content, view);
  }
}

export function render(stylesheets, view) {
  let new_view = view();
  let $ = $ffi.get_cache();
  if (!$.isOk()) {
    throw makeError(
      "let_assert",
      "sketch/magic",
      21,
      "render",
      "Pattern match failed, no pattern matched the value.",
      { value: $ }
    )
  }
  let cache = $[0];
  let content = $sketch.render(cache);
  return $list.fold(
    stylesheets,
    new_view,
    (view, stylesheet) => {
      return render_stylesheet(content, stylesheet, view);
    },
  );
}

export function class_name(class$) {
  let $ = $ffi.get_cache();
  if (!$.isOk()) {
    throw makeError(
      "let_assert",
      "sketch/magic",
      47,
      "class_name",
      "Pattern match failed, no pattern matched the value.",
      { value: $ }
    )
  }
  let cache = $[0];
  let $1 = $sketch.class_name(class$, cache);
  let cache$1 = $1[0];
  let class_name$1 = $1[1];
  $ffi.set_cache(cache$1);
  return class_name$1;
}

export function document() {
  let stylesheet = $ffi.create_document_stylesheet();
  return new CssStyleSheet(stylesheet);
}

export function shadow(root) {
  let stylesheet = $ffi.create_shadow_root_stylesheet(root);
  return new CssStyleSheet(stylesheet);
}

export function node() {
  return new NodeStyleSheet();
}
