/// <reference types="./setup.d.mts" />
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $grille_pain from "../../grille_pain/grille_pain.mjs";
import * as $options from "../../grille_pain/grille_pain/options.mjs";
import * as $lustre from "../../lustre/lustre.mjs";
import * as $modem from "../../modem/modem.mjs";
import * as $sketch from "../../sketch/sketch.mjs";
import * as $magic from "../../sketch_magic/sketch/magic.mjs";
import * as $msg from "../data/msg.mjs";
import * as $router from "../frontend/router.mjs";
import * as $sr from "../frontend/view/body/search_result.mjs";
import { Ok } from "../gleam.mjs";
import * as $lazy from "../lustre/lazy.mjs";

export function sketch() {
  return $result.try$(
    $sketch.cache(new $sketch.Ephemeral()),
    (cache) => {
      return $result.try$(
        $magic.setup(cache),
        (_) => { return new Ok(undefined); },
      );
    },
  );
}

export function components() {
  return $result.try$(
    $lazy.setup(),
    (_) => {
      return $result.try$($sr.setup(), (_) => { return new Ok(undefined); });
    },
  );
}

export function grille_pain() {
  let _pipe = $options.default$();
  let _pipe$1 = $options.timeout(_pipe, 5000);
  return $grille_pain.setup(_pipe$1);
}

export function start_application(init, update, view) {
  let _pipe = $lustre.application(init, update, view);
  return $lustre.start(_pipe, "#app", undefined);
}

export function modem() {
  return $modem.init(
    (uri) => {
      let _pipe = $router.parse_uri(uri);
      return new $msg.BrowserChangedRoute(_pipe);
    },
  );
}

export function initial_route() {
  let _pipe = $modem.initial_uri();
  let _pipe$1 = $result.map(_pipe, $router.parse_uri);
  return $result.unwrap(_pipe$1, new $router.Home());
}
