/// <reference types="./window.d.mts" />
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $dynamic from "../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $event from "../../../lustre/lustre/event.mjs";
import * as $msg from "../../data/msg.mjs";
import {
  subscribeFocus as do_subscribe_focus,
  subscribeIsMobile as do_suscribe_is_mobile,
  focus as do_focus,
  isActive as do_is_active,
  eventKey as do_key,
  blur as do_blur,
  scrollTo as do_scroll_to,
} from "../../gloogle.ffi.mjs";

export { do_scroll_to };

export function subscribe_is_mobile() {
  return $effect.from(
    (dispatch) => {
      return do_suscribe_is_mobile(
        (is_mobile) => {
          return dispatch(new $msg.BrowserResizedViewport(is_mobile));
        },
      );
    },
  );
}

export function focus(id, event) {
  return $effect.from(
    (_) => {
      return $bool.guard(
        do_is_active(id),
        undefined,
        () => {
          $event.prevent_default(event);
          return do_focus(id, event);
        },
      );
    },
  );
}

export function subscribe_focus() {
  return $effect.from(
    (dispatch) => {
      return do_subscribe_focus(
        (event) => {
          let $ = do_key(event);
          if ($.isOk() && $[0] === "Escape") {
            return dispatch(new $msg.UserPressedEscape());
          } else {
            return dispatch(new $msg.UserFocusedSearch(event));
          }
        },
      );
    },
  );
}

export function blur() {
  return $effect.from((_) => { return do_blur(); });
}

export function scroll_to(id) {
  return $effect.from((_) => { return do_scroll_to(id); });
}
