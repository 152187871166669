/// <reference types="./decoder_extra.d.mts" />
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import { Error, toList } from "../gleam.mjs";

export function completely_option(field) {
  return (dyn) => {
    let _pipe = $dynamic.optional_field(
      field,
      $dynamic.optional($dynamic.string),
    )(dyn);
    return $result.map(_pipe, (res) => { return $option.flatten(res); });
  };
}

export function json(decoder) {
  return $dynamic.any(
    toList([
      decoder,
      (dyn) => {
        let $ = $dynamic.string(dyn);
        if (!$.isOk()) {
          let e = $[0];
          return new Error(e);
        } else {
          let content = $[0];
          let _pipe = $json.decode(content, decoder);
          return $result.replace_error(_pipe, toList([]));
        }
      },
    ]),
  );
}
