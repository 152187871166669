/// <reference types="./discuss.d.mts" />
import * as $fetch from "../../gleam_fetch/gleam/fetch.mjs";
import * as $http from "../../gleam_http/gleam/http.mjs";
import * as $request from "../../gleam_http/gleam/http/request.mjs";
import * as $response from "../../gleam_http/gleam/http/response.mjs";
import * as $promise from "../../gleam_javascript/gleam/javascript/promise.mjs";
import * as $bool from "../../gleam_stdlib/gleam/bool.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../gleam_stdlib/gleam/string.mjs";
import * as $uri from "../../gleam_stdlib/gleam/uri.mjs";
import * as $vitools from "../../vitools/vitools.mjs";
import { Ok, toList, CustomType as $CustomType, makeError } from "../gleam.mjs";

export class InternalServerError extends $CustomType {}

export class InvalidJsonBody extends $CustomType {}

export class DecodeError extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class NetworkError extends $CustomType {}

export class NotFound extends $CustomType {}

class Discuss extends $CustomType {
  constructor(path, query, method, decoder, on_success, on_error) {
    super();
    this.path = path;
    this.query = query;
    this.method = method;
    this.decoder = decoder;
    this.on_success = on_success;
    this.on_error = on_error;
  }
}

export function via(discuss, method) {
  return discuss.withFields({ method: method });
}

export function query(discuss, query) {
  return discuss.withFields({ query: query });
}

export function expect(discuss, decoder) {
  let path = discuss.path;
  let query$1 = discuss.query;
  let method = discuss.method;
  return new Discuss(
    path,
    query$1,
    method,
    decoder,
    (_) => { return undefined; },
    (_) => { return undefined; },
  );
}

export function on_success(discuss, on_success) {
  return discuss.withFields({ on_success: on_success });
}

export function on_error(discuss, on_error) {
  return discuss.withFields({ on_error: on_error });
}

function prepend_slash(path) {
  if (path.startsWith("/")) {
    let path$1 = path.slice(1);
    return path$1;
  } else {
    let path$1 = path;
    return "/" + path$1;
  }
}

function encode_path(segments) {
  let _pipe = segments;
  let _pipe$1 = $list.map(_pipe, $uri.percent_encode);
  let _pipe$2 = $string.join(_pipe$1, "/");
  return prepend_slash(_pipe$2);
}

export function about(segments) {
  let path = encode_path(segments);
  return new Discuss(
    path,
    toList([]),
    new $http.Get(),
    (a) => { return new Ok(a); },
    (_) => { return undefined; },
    (_) => { return undefined; },
  );
}

function endpoint() {
  let _pipe = (() => {
    let $ = $vitools.is_dev();
    if ($) {
      return "http://localhost:3000";
    } else {
      return "https://api.gloogle.run";
    }
  })();
  return $request.to(_pipe);
}

function map_fetch_error(error) {
  if (error instanceof $fetch.InvalidJsonBody) {
    return new InvalidJsonBody();
  } else if (error instanceof $fetch.NetworkError) {
    return new NetworkError();
  } else {
    return new InvalidJsonBody();
  }
}

function handle_status(response, status_code, return$, continuation) {
  let is_matching_status = status_code === response.status;
  return $bool.lazy_guard(
    is_matching_status,
    return$,
    () => { return continuation(); },
  );
}

export function start(discuss) {
  let $ = endpoint();
  if (!$.isOk()) {
    throw makeError(
      "let_assert",
      "frontend/discuss",
      74,
      "start",
      "Pattern match failed, no pattern matched the value.",
      { value: $ }
    )
  }
  let req = $[0];
  let _pipe = req;
  let _pipe$1 = $request.set_path(_pipe, discuss.path);
  let _pipe$2 = $request.set_query(_pipe$1, discuss.query);
  let _pipe$3 = $fetch.send(_pipe$2);
  let _pipe$4 = $promise.try_await(_pipe$3, $fetch.read_json_body);
  let _pipe$5 = $promise.map(
    _pipe$4,
    (result) => { return $result.map_error(result, map_fetch_error); },
  );
  return $promise.tap(
    _pipe$5,
    (res) => {
      if (!res.isOk()) {
        let error = res[0];
        return discuss.on_error(error);
      } else {
        let res$1 = res[0];
        let warn = (error) => {
          return () => { return discuss.on_error(error); };
        };
        return handle_status(
          res$1,
          404,
          warn(new NotFound()),
          () => {
            return handle_status(
              res$1,
              500,
              warn(new InternalServerError()),
              () => {
                let $1 = discuss.decoder(res$1.body);
                if (!$1.isOk()) {
                  let error = $1[0];
                  return discuss.on_error(new DecodeError(error));
                } else {
                  let res$2 = $1[0];
                  return discuss.on_success(res$2);
                }
              },
            );
          },
        );
      }
    },
  );
}
