/// <reference types="./error.d.mts" />
import * as $io from "../../gleam_stdlib/gleam/io.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import { Some } from "../../gleam_stdlib/gleam/option.mjs";
import * as $discuss from "../frontend/discuss.mjs";

export function describe_http_error(error) {
  if (error instanceof $discuss.InternalServerError) {
    return new Some("Internal server error. Please try again later.");
  } else if (error instanceof $discuss.NetworkError) {
    return new Some("Network error. Please try again later.");
  } else if (error instanceof $discuss.NotFound) {
    return new Some("Resource not found. Make sure you have the correct URL.");
  } else if (error instanceof $discuss.InvalidJsonBody) {
    return new Some("Invalid JSON body. Please, retry later.");
  } else {
    let error$1 = error[0];
    $io.debug(error$1);
    return new Some("Format error. Please try again later.");
  }
}
