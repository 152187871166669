/// <reference types="./search_result.d.mts" />
import * as $bool from "../../../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../../../gleam_stdlib/gleam/dict.mjs";
import * as $dynamic from "../../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $list from "../../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../../../gleam_stdlib/gleam/result.mjs";
import * as $implementations from "../../../../interfaces/data/implementations.mjs";
import * as $type_search from "../../../../interfaces/data/type_search.mjs";
import * as $coerce from "../../../../interfaces/gleam/coerce.mjs";
import * as $lustre from "../../../../lustre/lustre.mjs";
import * as $a from "../../../../lustre/lustre/attribute.mjs";
import * as $effect from "../../../../lustre/lustre/effect.mjs";
import * as $element from "../../../../lustre/lustre/element.mjs";
import * as $h from "../../../../lustre/lustre/element/html.mjs";
import * as $e from "../../../../lustre/lustre/event.mjs";
import * as $search_result from "../../../data/search_result.mjs";
import * as $palette from "../../../frontend/colors/palette.mjs";
import * as $icons from "../../../frontend/icons.mjs";
import * as $signature from "../../../frontend/view/body/signature.mjs";
import * as $documentation from "../../../frontend/view/documentation.mjs";
import * as $t from "../../../frontend/view/types.mjs";
import { Ok, toList, CustomType as $CustomType, makeError } from "../../../gleam.mjs";

export class Model extends $CustomType {
  constructor(item, opened) {
    super();
    this.item = item;
    this.opened = opened;
  }
}

export class Received extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ToggleOpen extends $CustomType {}

function on_attribute_change() {
  let on_coerce = (() => {
    let _pipe = (dyn) => { return new Ok($coerce.coerce(dyn)); };
    return $dynamic.optional(_pipe);
  })();
  return $dict.from_list(
    toList([
      [
        "item",
        (dyn) => {
          let _pipe = on_coerce(dyn);
          return $result.map(_pipe, (var0) => { return new Received(var0); });
        },
      ],
    ]),
  );
}

export function view(item) {
  let attributes = toList([$a.property("item", item)]);
  return $element.element("search-result", attributes, toList([]));
}

function update(model, msg) {
  let _pipe = (() => {
    if (msg instanceof ToggleOpen) {
      return model.withFields({ opened: !model.opened });
    } else {
      let search_result = msg[0];
      return new Model(search_result, false);
    }
  })();
  return $pair.new$(_pipe, $effect.none());
}

function implementation_pill(item) {
  let content = item[0];
  let background = item[2];
  let style = $a.style(toList([["background", background]]));
  return $h.div(
    toList([$a.class$("implementations-pill-container")]),
    toList([
      $h.div(toList([$a.class$("implementations-pill"), style]), toList([])),
      $h.text(content),
    ]),
  );
}

function implementation_pills(implementations) {
  if (implementations instanceof $implementations.Implementations &&
  implementations.gleam &&
  !implementations.uses_erlang_externals &&
  !implementations.uses_javascript_externals) {
    return $element.none();
  } else {
    let gleam = implementations.gleam;
    let erl = implementations.uses_erlang_externals;
    let js = implementations.uses_javascript_externals;
    let _pipe = toList([
      ["Gleam", gleam, $palette.dark.faff_pink, $palette.dark.blacker],
      ["Erlang", erl, $palette.erlang, $palette.dark.white],
      ["JavaScript", js, $palette.javascript, $palette.dark.blacker],
    ]);
    let _pipe$1 = $list.filter(_pipe, (item) => { return item[1]; });
    let _pipe$2 = $list.map(_pipe$1, implementation_pill);
    return ((_capture) => {
      return $h.div(
        toList([$a.class$("implementations-pill-wrapper")]),
        _capture,
      );
    })(_pipe$2);
  }
}

function view_name(item) {
  let class$ = $a.class$("qualified-name");
  let href = $search_result.hexdocs_link(item);
  return $h.a(
    toList([class$, $a.target("_blank"), $a.rel("noreferrer"), $a.href(href)]),
    toList([
      $t.white(item.package_name),
      $t.dark_white("@" + item.version),
      $t.dark_white("."),
      $t.keyword(item.module_name),
      $t.dark_white("."),
      $t.fun(item.type_name),
    ]),
  );
}

function view_documentation_arrow(model, item) {
  return $bool.guard(
    item.documentation === "",
    $element.none(),
    () => {
      let no_implementation = $option.is_none(item.metadata.implementations);
      return $bool.guard(
        no_implementation,
        $element.none(),
        () => {
          let class$ = $a.class$("search-details-arrow-expand");
          let data_opened = $a.attribute(
            "data-opened",
            $bool.to_string(model.opened),
          );
          return $h.button(
            toList([class$, data_opened, $e.on_click(new ToggleOpen())]),
            toList([
              $h.span(
                toList([]),
                toList([
                  $element.text(
                    (() => {
                      let $ = model.opened;
                      if ($) {
                        return "Hide";
                      } else {
                        return "Show";
                      }
                    })() + " documentation",
                  ),
                ]),
              ),
              $icons.arrow(),
            ]),
          );
        },
      );
    },
  );
}

function view_implementation_pills(model, item) {
  return $bool.guard(
    !model.opened,
    $element.none(),
    () => {
      let _pipe = item.metadata.implementations;
      let _pipe$1 = $option.map(_pipe, implementation_pills);
      return $option.unwrap(_pipe$1, $element.none());
    },
  );
}

function view_documentation(model, item) {
  return $bool.guard(
    item.documentation === "",
    $element.none(),
    () => {
      return $bool.guard(
        !model.opened,
        $element.none(),
        () => {
          return $h.div(
            toList([$a.class$("documentation")]),
            toList([$documentation.view(item.documentation)]),
          );
        },
      );
    },
  );
}

function internal_view(model) {
  return $bool.guard(
    $option.is_none(model.item),
    $element.none(),
    () => {
      let $ = model.item;
      if (!($ instanceof $option.Some)) {
        throw makeError(
          "let_assert",
          "frontend/view/body/search_result",
          84,
          "",
          "Pattern match failed, no pattern matched the value.",
          { value: $ }
        )
      }
      let item = $[0];
      let package_id = (item.package_name + "@") + item.version;
      let id = (((package_id + "-") + item.module_name) + "-") + item.type_name;
      return $h.div(
        toList([$a.class$("search-result"), $a.id(id)]),
        toList([
          $h.div(
            toList([$a.class$("search-details")]),
            toList([
              $h.div(
                toList([$a.class$("search-details-name")]),
                toList([
                  view_name(item),
                  $h.div(
                    toList([$a.class$("external-icon-wrapper")]),
                    toList([$icons.external_link()]),
                  ),
                ]),
              ),
              view_documentation_arrow(model, item),
            ]),
          ),
          $h.div(
            toList([$a.class$("search-body")]),
            toList([
              $h.code(
                toList([$a.class$("signature")]),
                $signature.view_signature(item),
              ),
            ]),
          ),
          view_implementation_pills(model, item),
          view_documentation(model, item),
        ]),
      );
    },
  );
}

export function setup() {
  let attrs = on_attribute_change();
  let init = (_) => {
    return [new Model(new $option.None(), false), $effect.none()];
  };
  let _pipe = $lustre.component(init, update, internal_view, attrs);
  return $lustre.register(_pipe, "search-result");
}
