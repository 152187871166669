/// <reference types="./styles.d.mts" />
import * as $s from "../../../../sketch/sketch.mjs";
import * as $media from "../../../../sketch/sketch/media.mjs";
import * as $size from "../../../../sketch/sketch/size.mjs";
import { px } from "../../../../sketch/sketch/size.mjs";
import * as $h from "../../../../sketch_magic/sketch/magic/element/html.mjs";
import { toList } from "../../../gleam.mjs";

export function search_with_filters(attributes, children) {
  let _pipe = $s.class$(
    toList([
      $s.grid_area("input"),
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.gap(px(12)),
    ]),
  );
  return $h.div(_pipe, attributes, children);
}

export function search_input_wrapper(loading, children) {
  let _pipe = $s.class$(
    toList([
      $s.border_radius(px(12)),
      $s.overflow("hidden"),
      $s.padding(
        px(
          (() => {
            if (loading) {
              return 4;
            } else {
              return 0;
            }
          })(),
        ),
      ),
      $s.background(
        "linear-gradient(-45deg, #4ce7ff, #c651e5, #e3d8be, #4ce7ff)",
      ),
      $s.property("background-size", "400% 400%"),
      $s.transition("padding .3s"),
      $s.animation("bg-spin 3s linear infinite"),
      $s.border("1px solid var(--border-color)"),
      $s.animation_play_state(
        (() => {
          if (loading) {
            return "running";
          } else {
            return "paused";
          }
        })(),
      ),
    ]),
  );
  return $h.div(_pipe, toList([]), children);
}

export function search_input(loading, small, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.gap(px(6)),
      $s.border_radius(px(8)),
      $s.color("var(--input-text-color)"),
      $s.background(
        (() => {
          if (!small) {
            return "var(--input-background)";
          } else {
            return "var(--dark-background)";
          }
        })(),
      ),
      $s.transition("padding .3s"),
      $s.align_items("center"),
      $s.padding(
        px(
          (() => {
            if (loading && !small) {
              return 16;
            } else if (!loading && !small) {
              return 20;
            } else if (loading && small) {
              return 6;
            } else {
              return 10;
            }
          })(),
        ),
      ),
    ]),
  );
  return $h.div(_pipe, toList([]), children);
}

export function search_input_content(attributes) {
  let _pipe = $s.class$(
    toList([
      $s.appearance("none"),
      $s.border("none"),
      $s.outline("none"),
      $s.width($size.percent(100)),
      $s.line_height("1.5"),
      $s.background("transparent"),
      $s.color("inherit"),
    ]),
  );
  return $h.input(_pipe, attributes);
}

export function shortcut_hint(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.white_space("nowrap"),
      $s.font_size(px(11)),
      $s.border("1px solid var(--text-color)"),
      $s.padding_("3px 6px"),
      $s.border_radius(px(6)),
      $s.opacity(0.4),
      $s.media($media.max_width(px(700)), toList([$s.display("none")])),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}
