/// <reference types="./api.d.mts" />
import * as $dynamic from "../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $analytics from "../../../interfaces/data/analytics.mjs";
import * as $package from "../../../interfaces/data/package.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $model from "../../data/model.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $search_result from "../../data/search_result.mjs";
import * as $discuss from "../../frontend/discuss.mjs";
import { toList } from "../../gleam.mjs";

export function get_trendings() {
  return $effect.from(
    (dispatch) => {
      let _pipe = $discuss.about(toList(["trendings"]));
      let _pipe$1 = $discuss.expect(_pipe, $dynamic.list($package.decode));
      let _pipe$2 = $discuss.on_success(
        _pipe$1,
        (m) => { return dispatch(new $msg.ApiReturnedTrendings(m)); },
      );
      let _pipe$3 = $discuss.on_error(
        _pipe$2,
        (e) => { return dispatch(new $msg.AppRequiredDiscussToast(e)); },
      );
      $discuss.start(_pipe$3)
      return undefined;
    },
  );
}

export function get_packages() {
  return $effect.from(
    (dispatch) => {
      let _pipe = $discuss.about(toList(["packages"]));
      let _pipe$1 = $discuss.expect(_pipe, $dynamic.list($package.decode));
      let _pipe$2 = $discuss.on_success(
        _pipe$1,
        (m) => { return dispatch(new $msg.ApiReturnedPackages(m)); },
      );
      let _pipe$3 = $discuss.on_error(
        _pipe$2,
        (e) => { return dispatch(new $msg.AppRequiredDiscussToast(e)); },
      );
      $discuss.start(_pipe$3)
      return undefined;
    },
  );
}

export function get_analytics() {
  return $effect.from(
    (dispatch) => {
      let _pipe = $discuss.about(toList(["analytics"]));
      let _pipe$1 = $discuss.expect(_pipe, $analytics.decode);
      let _pipe$2 = $discuss.on_success(
        _pipe$1,
        (m) => { return dispatch(new $msg.ApiReturnedAnalytics(m)); },
      );
      let _pipe$3 = $discuss.on_error(
        _pipe$2,
        (e) => { return dispatch(new $msg.AppRequiredDiscussToast(e)); },
      );
      $discuss.start(_pipe$3)
      return undefined;
    },
  );
}

export function get_search(data) {
  return $effect.from(
    (dispatch) => {
      let _pipe = $discuss.about(toList(["search"]));
      let _pipe$1 = $discuss.query(_pipe, toList([["q", data.input]]));
      let _pipe$2 = $discuss.expect(
        _pipe$1,
        $search_result.decode_search_results,
      );
      let _pipe$3 = $discuss.on_success(
        _pipe$2,
        (search_results) => {
          let _pipe$3 = data.input;
          let _pipe$4 = ((_capture) => {
            return new $msg.ApiReturnedSearchResults(_capture, search_results);
          })(_pipe$3);
          return dispatch(_pipe$4);
        },
      );
      let _pipe$4 = $discuss.on_error(
        _pipe$3,
        (e) => { return dispatch(new $msg.AppRequiredDiscussToast(e)); },
      );
      $discuss.start(_pipe$4)
      return undefined;
    },
  );
}
