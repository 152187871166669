/// <reference types="./package.d.mts" />
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import { toList, CustomType as $CustomType } from "../gleam.mjs";

export class Package extends $CustomType {
  constructor(name, repository, documentation, hex_url, licenses, description, rank, popularity) {
    super();
    this.name = name;
    this.repository = repository;
    this.documentation = documentation;
    this.hex_url = hex_url;
    this.licenses = licenses;
    this.description = description;
    this.rank = rank;
    this.popularity = popularity;
  }
}

export function decode(dyn) {
  return $dynamic.decode8(
    (var0, var1, var2, var3, var4, var5, var6, var7) => {
      return new Package(var0, var1, var2, var3, var4, var5, var6, var7);
    },
    $dynamic.field("name", $dynamic.string),
    $dynamic.field("repository", $dynamic.optional($dynamic.string)),
    $dynamic.field("documentation", $dynamic.optional($dynamic.string)),
    $dynamic.field("hex_url", $dynamic.optional($dynamic.string)),
    $dynamic.field(
      "licenses",
      (dyn) => {
        return $dynamic.any(
          toList([
            $dynamic.list($dynamic.string),
            (dyn) => {
              return $result.try$(
                $dynamic.optional($dynamic.string)(dyn),
                (data) => {
                  let _pipe = $option.unwrap(data, "[]");
                  let _pipe$1 = $json.decode(
                    _pipe,
                    $dynamic.list($dynamic.string),
                  );
                  return $result.replace_error(
                    _pipe$1,
                    toList([new $dynamic.DecodeError("", "", toList([]))]),
                  );
                },
              );
            },
          ]),
        )(dyn);
      },
    ),
    $dynamic.field("description", $dynamic.optional($dynamic.string)),
    $dynamic.field("rank", $dynamic.optional($dynamic.int)),
    $dynamic.field(
      "popularity",
      (dyn) => {
        return $result.try$(
          $dynamic.optional($dynamic.string)(dyn),
          (data) => {
            let _pipe = $option.unwrap(data, "{}");
            let _pipe$1 = $json.decode(
              _pipe,
              $dynamic.optional_field("github", $dynamic.int),
            );
            let _pipe$2 = $result.map(
              _pipe$1,
              (_capture) => { return $option.unwrap(_capture, 0); },
            );
            return $result.replace_error(
              _pipe$2,
              toList([new $dynamic.DecodeError("", "", toList([]))]),
            );
          },
        );
      },
    ),
  )(dyn);
}

export function encode(package$) {
  return $json.object(
    toList([
      ["name", $json.string(package$.name)],
      ["repository", $json.nullable(package$.repository, $json.string)],
      ["documentation", $json.nullable(package$.documentation, $json.string)],
      ["hex_url", $json.nullable(package$.hex_url, $json.string)],
      ["licenses", $json.array(package$.licenses, $json.string)],
      ["description", $json.nullable(package$.description, $json.string)],
      ["rank", $json.nullable(package$.rank, $json.int)],
      [
        "popularity",
        (() => {
          let _pipe = $json.object(
            toList([["github", $json.int(package$.popularity)]]),
          );
          let _pipe$1 = $json.to_string(_pipe);
          return $json.string(_pipe$1);
        })(),
      ],
    ]),
  );
}
