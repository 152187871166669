/// <reference types="./styles.d.mts" />
import * as $a from "../../../../lustre/lustre/attribute.mjs";
import * as $element from "../../../../lustre/lustre/element.mjs";
import * as $s from "../../../../sketch/sketch.mjs";
import * as $media from "../../../../sketch/sketch/media.mjs";
import * as $size from "../../../../sketch/sketch/size.mjs";
import { px, vh, vw } from "../../../../sketch/sketch/size.mjs";
import * as $h from "../../../../sketch_magic/sketch/magic/element/html.mjs";
import * as $palette from "../../../frontend/colors/palette.mjs";
import { toList, prepend as listPrepend } from "../../../gleam.mjs";

export function implementations_pill_container(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.align_items("center"),
      $s.font_size(px(14)),
      $s.font_weight("300"),
      $s.gap(px(6)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function implementations_pill(background, attributes, children) {
  let _pipe = $s.class$(
    toList([
      $s.background(background),
      $s.border_radius(px(6)),
      $s.width(px(8)),
      $s.height(px(8)),
    ]),
  );
  return $h.div(_pipe, attributes, children);
}

export function implementations_pill_wrapper(attributes, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.align_items("center"),
      $s.gap(px(12)),
      $s.justify_content("end"),
    ]),
  );
  return $h.div(_pipe, attributes, children);
}

export function search_result(attributes, children) {
  let _pipe = $s.class$(
    toList([
      $s.overflow("hidden"),
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.gap(px(12)),
    ]),
  );
  return $h.div(_pipe, attributes, children);
}

export function search_results_wrapper(attributes, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("grid"),
      $s.padding_right(px(48)),
      $s.gap(px(36)),
      $s.grid_template_columns("min-content 1fr"),
      $s.justify_items("center"),
      $s.media(
        $media.max_width(px(700)),
        toList([$s.grid_template_columns("1fr"), $s.padding_("0 24px")]),
      ),
    ]),
  );
  return $h.div(_pipe, attributes, children);
}

export function external_icon_wrapper(attrs, children) {
  let _pipe = $s.class$(toList([$s.width(px(16)), $s.height(px(16))]));
  return $h.div(_pipe, attrs, children);
}

export function search_details(attributes, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.gap(px(12)),
      $s.align_items("center"),
      $s.media($media.max_width(px(700)), toList([$s.flex_direction("column")])),
    ]),
  );
  return $h.div(_pipe, attributes, children);
}

export function search_details_title(attributes, children) {
  let _pipe = $s.class$(
    toList([$s.display("flex"), $s.align_items("center"), $s.gap(px(12))]),
  );
  return $h.div(_pipe, attributes, children);
}

export function qualified_name(attributes, children) {
  let _pipe = $s.class$(
    toList([
      $s.overflow("hidden"),
      $s.text_overflow("ellipsis"),
      $s.text_decoration("none"),
      $s.hover(toList([$s.text_decoration("underline")])),
    ]),
  );
  return $h.a(_pipe, attributes, children);
}

export function search_body(attributes, children) {
  let _pipe = $s.class$(
    toList([
      $s.background("rgba(254, 254, 252, 0.05)"),
      $s.border_radius(px(12)),
      $s.padding_("12px 24px"),
      $s.border("1px solid rgba(254, 254, 252, .1)"),
    ]),
  );
  return $h.div(_pipe, attributes, children);
}

export function signature(attributes, children) {
  let _pipe = $s.class$(
    toList([
      $s.white_space("pre-wrap"),
      $s.display("block"),
      $s.line_height("1.6"),
      $s.overflow("auto"),
    ]),
  );
  return $h.code(_pipe, attributes, children);
}

export function documentation(attributes, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.gap(px(12)),
      $s.padding_top(px(6)),
    ]),
  );
  return $h.div(_pipe, attributes, children);
}

export function search_result_separator() {
  let _pipe = $s.class$(
    toList([
      $s.height(px(1)),
      $s.background("rgba(254, 254, 252, 0.1)"),
      $s.margin_("6px 0"),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([]));
}

export function documentation_title(attributes, children) {
  let _pipe = $s.class$(toList([$s.color($palette.dark.dark_white)]));
  return $h.div(_pipe, attributes, children);
}

export function search_wrapper(attributes, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("grid"),
      $s.grid_template_rows("auto auto auto"),
      $s.grid_template_columns("auto auto auto"),
      $s.grid_template_areas(
        toList(["title . .", "input input input", ". . submit"]),
      ),
      $s.padding(px(48)),
      $s.gap(px(24)),
      $s.max_width(px(700)),
      $s.width($size.percent(100)),
      $s.margin_("auto"),
      $s.media(
        $media.max_width(px(700)),
        toList([
          $s.max_width(vw(100)),
          $s.padding(px(24)),
          $s.grid_template_areas(
            toList(["title title title", "input input input", ". . submit"]),
          ),
        ]),
      ),
    ]),
  );
  return $h.form(_pipe, attributes, children);
}

export function search_title_() {
  return $s.class$(
    toList([
      $s.font_family("Lexend"),
      $s.display("flex"),
      $s.align_items("center"),
      $s.gap(px(12)),
      $s.color("var(--text-color)"),
    ]),
  );
}

export function search_title(attributes, children) {
  let _pipe = $s.class$(
    toList([$s.compose(search_title_()), $s.font_size($size.rem(2.5))]),
  );
  return $h.div(_pipe, attributes, children);
}

export function search_title_wrapper(attributes, children) {
  let _pipe = $s.class$(
    toList([
      $s.grid_area("title"),
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.gap(px(9)),
      $s.font_size($size.rem(0.9)),
      $s.color($palette.dark.dark_white),
      $s.line_height("1.3"),
    ]),
  );
  return $h.div(_pipe, attributes, children);
}

export function search_lucy(size, attributes) {
  let _pipe = $s.class$(toList([$s.width(px(size))]));
  return $h.img(_pipe, attributes);
}

export function search_submit(attributes) {
  let _pipe = $s.class$(
    toList([
      $s.grid_area("submit"),
      $s.appearance("none"),
      $s.border("none"),
      $s.background($palette.dark.faff_pink),
      $s.border_radius(px(50)),
      $s.padding_top(px(12)),
      $s.padding_bottom(px(12)),
      $s.padding_right(px(24)),
      $s.padding_left(px(24)),
      $s.color($palette.dark.charcoal),
      $s.font_size($size.rem(1.0)),
      $s.outline("none"),
      $s.transition("background .3s"),
      $s.active(toList([$s.background($palette.dark.dark_faff_pink)])),
      $s.focus(toList([$s.background($palette.dark.dark_faff_pink)])),
      $s.disabled(toList([$s.background($palette.dark.unexpected_aubergine)])),
    ]),
  );
  return $h.input(_pipe, attributes);
}

export function matches_titles(attributes, children) {
  let _pipe = $s.class$(
    toList([
      $s.line_height("1.3"),
      $s.color($palette.dark.dark_white),
      $s.display("flex"),
      $s.align_items("baseline"),
      $s.gap(px(6)),
      $s.font_size(px(12)),
      $s.media($media.max_width(px(700)), toList([$s.flex_direction("column")])),
    ]),
  );
  return $h.div(_pipe, attributes, children);
}

export function matches_title(attributes, children) {
  let _pipe = $s.class$(
    toList([$s.color($palette.dark.white), $s.font_size(px(18))]),
  );
  return $h.div(_pipe, attributes, children);
}

export function empty_state(attributes, children) {
  let _pipe = $s.class$(
    toList([
      $s.grid_row("span 3"),
      $s.display("flex"),
      $s.align_items("center"),
      $s.gap(px(24)),
      $s.justify_content("center"),
      $s.max_width(px(700)),
      $s.width($size.percent(100)),
      $s.margin_("auto"),
      $s.padding(px(24)),
    ]),
  );
  return $h.div(_pipe, attributes, children);
}

export function empty_state_lucy(attributes) {
  let _pipe = $s.class$(toList([$s.width(px(100))]));
  return $h.img(_pipe, attributes);
}

export function empty_state_titles(attributes, children) {
  let _pipe = $s.class$(
    toList([
      $s.font_size(px(20)),
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.gap(px(9)),
      $s.line_height("1.3"),
      $s.max_width(px(400)),
    ]),
  );
  return $h.div(_pipe, attributes, children);
}

export function empty_state_subtitle(attributes, children) {
  let _pipe = $s.class$(
    toList([$s.font_size(px(16)), $s.color($palette.dark.dark_white)]),
  );
  return $h.div(_pipe, attributes, children);
}

export function sidebar_wrapper(attributes, children) {
  let _pipe = $s.class$(
    toList([
      $s.position("sticky"),
      $s.top(px(0)),
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.gap(px(12)),
      $s.height(vh(100)),
      $s.overflow("auto"),
      $s.padding_("12px"),
      $s.border_right("1px solid rgba(254, 254, 252, .1)"),
      $s.property(
        "mask",
        "linear-gradient(\n        180deg,\n        rgba(255,255,255, 0) 0%,\n        rgba(255,255,255,1) 0.5% 99.5%,\n        rgba(255, 255, 255, 0)\n      )",
      ),
      $s.media($media.max_width(px(700)), toList([$s.display("none")])),
    ]),
  );
  return $h.div(_pipe, attributes, children);
}

export function sidebar_wrapper_title(attrs, children) {
  let _pipe = $s.class$(toList([$s.padding_("4px 4px"), $s.color("#ffffff99")]));
  return $h.div(_pipe, attrs, children);
}

export function sidebar_package_name(attributes, children) {
  let _pipe = $s.class$(
    toList([
      $s.padding_left(px(8)),
      $s.border_radius(px(6)),
      $s.overflow("hidden"),
      $s.text_overflow("ellipsis"),
    ]),
  );
  return $h.div(_pipe, attributes, children);
}

export function sidebar_module_name(attributes, children) {
  let _pipe = $s.class$(
    toList([
      $s.text_overflow("ellipsis"),
      $s.overflow("hidden"),
      $s.text_align("left"),
      $s.cursor("pointer"),
      $s.hover(toList([$s.text_decoration("underline")])),
      $s.appearance("none"),
      $s.background("inherit"),
      $s.border("none"),
      $s.color("inherit"),
      $s.font_size_("inherit"),
      $s.line_height("inherit"),
      $s.display("block"),
      $s.property("padding-block", "0"),
      $s.property("padding-inline", "0"),
      $s.padding_left(px(16)),
    ]),
  );
  return $h.button(_pipe, attributes, children);
}

export function sidebar_package_wrapper(attributes, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.gap(px(9)),
      $s.font_size(px(14)),
      $s.max_width(px(250)),
    ]),
  );
  return $h.div(_pipe, attributes, children);
}

export function main(attributes, children) {
  let _pipe = $s.class$(
    toList([
      $s.grid_area("main"),
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.gap(px(24)),
    ]),
  );
  return $h.main(_pipe, attributes, children);
}

export function sidebar_title(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.align_items("center"),
      $s.gap(px(16)),
      $s.color("inherit"),
      $s.text_decoration("none"),
    ]),
  );
  return $h.a(_pipe, attrs, children);
}

export function sidebar_title_inside(attrs, children) {
  let _pipe = $s.class$(toList([]));
  return $h.div(_pipe, attrs, children);
}

export function form_wrapper(attrs, children) {
  let _pipe = $s.class$(toList([]));
  return $h.form(_pipe, attrs, children);
}

export function sidebar_filter(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.padding_top(px(12)),
      $s.padding_left(px(12)),
      $s.color("rgba(254, 254, 252, .6)"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function sidebar_filters(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.gap(px(18)),
      $s.padding_("0 12px"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function sidebar_checkbox(active, attrs) {
  return $element.fragment(
    toList([
      (() => {
        let _pipe = $s.class$(
          toList([
            $s.width(px(16)),
            $s.height(px(16)),
            $s.background(
              (() => {
                if (active) {
                  return "#ffaff3";
                } else {
                  return "rgba(254, 254, 252, .1)";
                }
              })(),
            ),
            $s.border("1px solid rgba(254, 254, 252, .1)"),
            $s.border_radius(px(4)),
          ]),
        );
        return $h.div(_pipe, toList([]), toList([]));
      })(),
      (() => {
        let _pipe = $s.class$(
          toList([
            $s.position("fixed"),
            $s.top(px(-1000)),
            $s.width(px(1)),
            $s.height(px(1)),
          ]),
        );
        return $h.input(
          _pipe,
          listPrepend(
            $a.type_("checkbox"),
            listPrepend($a.checked(active), attrs),
          ),
        );
      })(),
    ]),
  );
}

export function sidebar_spacer(attrs, children) {
  let _pipe = $s.class$(toList([$s.flex("1")]));
  return $h.div(_pipe, attrs, children);
}

export function filter_separator(attrs, children) {
  let _pipe = $s.class$(
    toList([$s.height(px(1)), $s.background("rgba(254, 254, 252, .1)")]),
  );
  return $h.div(_pipe, attrs, children);
}

export function sidebar_filter_line(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.gap(px(9)),
      $s.cursor("pointer"),
      $s.align_items("center"),
    ]),
  );
  return $h.label(_pipe, attrs, children);
}

export function sidebar_filter_name(attrs, children) {
  let _pipe = $s.class$(toList([$s.color("white")]));
  return $h.div(_pipe, attrs, children);
}

export function sidebar_links(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.gap(px(24)),
      $s.padding(px(12)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function sidebar_link_wrapper(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.align_items("baseline"),
      $s.gap(px(9)),
      $s.text_decoration("none"),
      $s.color("inherit"),
    ]),
  );
  return $h.a(_pipe, attrs, children);
}

export function sidebar_icon(attrs, children) {
  let _pipe = $s.class$(toList([$s.width(px(12)), $s.height(px(12))]));
  return $h.div(_pipe, attrs, children);
}

export function sidebar_link(attrs, children) {
  let _pipe = $s.class$(
    toList([$s.font_size(px(14)), $s.color("rgba(254, 254, 252, 0.6)")]),
  );
  return $h.div(_pipe, attrs, children);
}

export function items_wrapper(attributes, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.gap(px(24)),
      $s.padding_top(px(24)),
      $s.max_width(px(700)),
      $s.width($size.percent(100)),
      $s.overflow("hidden"),
    ]),
  );
  return $h.div(_pipe, attributes, children);
}

export function named_type_button(attributes, children) {
  let _pipe = $s.class$(toList([$s.color("#e5c07b")]));
  return $h.a(_pipe, attributes, children);
}

export function search_title_with_hint(attributes, children) {
  let _pipe = $s.class$(toList([$s.display("flex"), $s.gap(px(12))]));
  return $h.div(_pipe, attributes, children);
}

export function pre_alpha_title(attributes, children) {
  let _pipe = $s.class$(toList([$s.font_size(px(16))]));
  return $h.div(_pipe, attributes, children);
}

export function loading_trending(attributes, children) {
  let _pipe = $s.class$(toList([]));
  return $h.div(_pipe, attributes, children);
}

export function no_trendings(attributes, children) {
  let _pipe = $s.class$(toList([]));
  return $h.div(_pipe, attributes, children);
}

export function trendings_wrapper(attributes, children) {
  let _pipe = $s.class$(toList([$s.padding_("12px 48px")]));
  return $h.div(_pipe, attributes, children);
}

export function trendings_title(attributes, children) {
  let _pipe = $s.class$(toList([$s.margin_bottom(px(24))]));
  return $h.div(_pipe, attributes, children);
}

export function trendings_grid(attributes, children) {
  let _pipe = $s.class$(
    toList([$s.display("grid"), $s.align_items("start"), $s.gap(px(24))]),
  );
  return $h.div(_pipe, attributes, children);
}

export function trendings_card(attributes, children) {
  let _pipe = $s.class$(
    toList([$s.background($palette.dark.unexpected_aubergine)]),
  );
  return $h.div(_pipe, attributes, children);
}

export function documentation_links(attributes, children) {
  let _pipe = $s.class$(
    toList([$s.display("flex"), $s.justify_content("space-between")]),
  );
  return $h.div(_pipe, attributes, children);
}

export function licenses(attributes, children) {
  let _pipe = $s.class$(toList([$s.display("flex"), $s.gap(px(6))]));
  return $h.div(_pipe, attributes, children);
}
