/// <reference types="./styles.d.mts" />
import * as $s from "../../../../sketch/sketch.mjs";
import * as $media from "../../../../sketch/sketch/media.mjs";
import * as $size from "../../../../sketch/sketch/size.mjs";
import { px, vw } from "../../../../sketch/sketch/size.mjs";
import * as $h from "../../../../sketch_magic/sketch/magic/element/html.mjs";
import * as $body_styles from "../../../frontend/view/body/styles.mjs";
import { toList } from "../../../gleam.mjs";

export function search_input_wrapper(attributes, children) {
  let _pipe = $s.class$(toList([$s.width_("100%")]));
  return $h.form(_pipe, attributes, children);
}

export function navbar_search_title(attributes, children) {
  let _pipe = $s.class$(
    toList([
      $s.font_size($size.rem(1.2)),
      $s.compose($body_styles.search_title_()),
      $s.text_decoration("none"),
      $s.cursor("pointer"),
    ]),
  );
  return $h.a(_pipe, attributes, children);
}

export function nav_links(attributes, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.align_items("baseline"),
      $s.gap(px(48)),
      $s.padding(px(48)),
      $s.media(
        $media.max_width(px(700)),
        toList([$s.padding(px(12)), $s.gap(px(24))]),
      ),
    ]),
  );
  return $h.div(_pipe, attributes, children);
}

export function coming_soon(attributes, children) {
  let _pipe = $s.class$(toList([$s.font_size($size.rem(0.7))]));
  return $h.span(_pipe, attributes, children);
}

export function trending(attributes, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.gap(px(3)),
      $s.align_items("end"),
      $s.color("var(--text-color)"),
      $s.white_space("nowrap"),
      $s.opacity(0.3),
    ]),
  );
  return $h.div(_pipe, attributes, children);
}

export function nav_link(attributes, children) {
  let _pipe = $s.class$(
    toList([$s.color("var(--text-color)"), $s.text_decoration("none")]),
  );
  return $h.a(_pipe, attributes, children);
}

export function navbar(transparent, attributes, children) {
  let _pipe = $s.class$(
    toList([
      $s.position("sticky"),
      $s.top(px(0)),
      $s.justify_content("end"),
      $s.grid_area("navbar"),
      $s.padding_left(px(48)),
      $s.gap(px(48)),
      $s.height(px(130)),
      $s.z_index(1000),
      $s.background(
        (() => {
          if (transparent) {
            return "transparent";
          } else {
            return "var(--sidebar-background)";
          }
        })(),
      ),
      $s.display(
        (() => {
          if (transparent) {
            return "flex";
          } else {
            return "none";
          }
        })(),
      ),
      $s.border_bottom(
        "1px solid " + (() => {
          if (transparent) {
            return "transparent";
          } else {
            return "var(--border-color)";
          }
        })(),
      ),
      $s.media(
        $media.max_width(px(700)),
        toList([
          $s.display("flex"),
          $s.gap(px(24)),
          $s.max_width(vw(100)),
          $s.height_("unset"),
          $s.padding_("18px 24px"),
        ]),
      ),
    ]),
  );
  return $h.nav(_pipe, attributes, children);
}

export function navbar_search(attributes, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.gap(px(48)),
      $s.align_items("center"),
      $s.flex("1"),
      $s.media($media.max_width(px(700)), toList([$s.gap(px(24))])),
    ]),
  );
  return $h.div(_pipe, attributes, children);
}

export function title(a, c) {
  return $h.div_(a, c);
}

export const search_lucy = $body_styles.search_lucy;
